import './../../styles/pages/candidates.scss';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  getCandidates,
  createCandidate,
  updateCandidate,
  deleteCandidate,
  getProfileImageDownloadUrl
} from '../../core/services/candidates';
import { getPositions } from '../../core/services/positions';
import { dateFormat } from '../../core/utils/dateFormat';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable
} from 'material-react-table';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  MenuItem,
  TextField
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import CsButton from './../../comps/CsButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Candidates = () => {
  const [data, setData] = useState([]);
  const [positions, setPositions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [lastDocument, setLastDocument] = useState();
  const [globalFilter, setGlobalFilter] = useState();
  const [searchedWithGlobal, setSearchedWithGlobal] = useState(false);
  const [profileImgPreview, setProfileImgPreview] = useState('');
  const [formValues, setFormValues] = useState({});
  const [previousProfileImg, setPreviousProfileImg] = useState('');

  const profileImgRef = useRef(null);

  const loadData = () => {
    setIsLoading(true);

    getCandidates({ action: 'load', batchLimit: limit }).then(res => {
      setData(res.candidates);
      setLastDocument(res.last);
      setIsLoading(false);
    });
  };

  const loadMoreData = () => {
    setIsLoading(true);

    getCandidates({
      action: 'more',
      batchLimit: limit,
      start: lastDocument
    }).then(res => {
      setData([...data, ...res.candidates]);
      setLastDocument(res.last);
      setIsLoading(false);
    });
  };

  const loadSearchData = () => {
    setIsLoading(true);

    getCandidates({
      action: 'search',
      batchLimit: null,
      start: null,
      search: globalFilter
    }).then(res => {
      setData(res.candidates);
      setIsLoading(false);
    });
  };

  const loadPositions = async () => {
    const res = await getPositions({ action: 'load', batchLimit: 100 });
    setPositions(res.positions);
  };

  useEffect(() => {
    loadPositions();
    loadData();
  }, []);

  useEffect(() => {
    if (globalFilter !== undefined) {
      loadSearchData();
      setSearchedWithGlobal(true);
    }
  }, [globalFilter]);

  const handleProfileImgPreview = event => {
    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setProfileImgPreview(objectUrl);
  };

  const handleUpdate = async ({ row, values, table }) => {
    table.setEditingRow(null);
    setIsLoading(true);
    await updateCandidate({
      ...formValues,
      id: row.original.id,
      profile_img: row.original.profile_img,
      file: profileImgRef.current.files[0],
      previousProfileImg: previousProfileImg || null
    });
    loadData();
  };

  const handleCreate = async ({ row, values, table }) => {
    table.setCreatingRow(null);
    setIsLoading(true);
    await createCandidate({
      ...formValues,
      file: profileImgRef.current.files[0]
    });
    loadData();
  };

  const openDeleteConfirmModal = async row => {
    if (
      window.confirm(
        `Are you sure you want to delete ${row.original.full_name}?`
      )
    ) {
      setIsLoading(true);
      await deleteCandidate(row.original.id);
      loadData();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'full_name',
        header: 'Full Name',
        size: 50
      },
      {
        accessorKey: 'position_id',
        header: 'Position',
        size: 50,
        Cell: ({ cell }) => {
          const position = positions.find(p => p.id === cell.getValue());
          return position ? position.title : 'Unknown';
        },
        muiEditTextFieldProps: {
          select: true,
          children: positions.map(position => (
            <MenuItem key={position.id} value={position.id}>
              {position.title}
            </MenuItem>
          ))
        }
      },
      {
        accessorKey: 'client_id',
        header: 'Client ID',
        size: 50
      },
      {
        accessorKey: 'info',
        header: 'Info',
        size: 50,
        muiEditTextFieldProps: {
          multiline: true,
          rows: 4
        }
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 50,
        editVariant: 'select',
        editSelectOptions: [
          {
            label: 'Active',
            value: 'Active'
          },
          {
            label: 'Inactive',
            value: 'Inactive'
          }
        ]
      }
    ],
    [positions]
  );

  const renderFormFields = () => (
    <>
      <input
        ref={profileImgRef}
        type="file"
        accept="image/*"
        onChange={handleProfileImgPreview}
        hidden
      />
      {profileImgPreview && (
        <div className="file-preview">
          <img
            src={profileImgPreview}
            alt="Profile Preview"
            width="100%"
            style={{ objectFit: 'contain' }}
          />
        </div>
      )}
      <CsButton
        label="Upload Profile Image"
        onClick={() => {
          profileImgRef.current.click();
        }}
      />
      <TextField
        label="Full Name"
        variant="standard"
        value={formValues.full_name || ''}
        onChange={e => {
          setFormValues({ ...formValues, full_name: e.target.value });
        }}
      />
      <TextField
        label="Position"
        variant="standard"
        select
        value={formValues.position_id || ''}
        onChange={e => {
          setFormValues({ ...formValues, position_id: e.target.value });
        }}
      >
        {positions.map(position => (
          <MenuItem key={position.id} value={position.id}>
            {position.title}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Client ID"
        variant="standard"
        value={formValues.client_id || ''}
        onChange={e => {
          setFormValues({ ...formValues, client_id: e.target.value });
        }}
      />
      <TextField
        label="Info"
        variant="standard"
        multiline
        rows={4}
        value={formValues.info || ''}
        onChange={e => {
          setFormValues({ ...formValues, info: e.target.value });
        }}
      />
    </>
  );

  const table = useMaterialReactTable({
    columns,
    data,
    createDisplayMode: 'modal',
    enableRowActions: true,
    enableEditing: true,
    manualFiltering: true,
    manualPagination: true,
    enableBottomToolbar: true,
    state: {
      isLoading,
      globalFilter
    },
    muiTableContainerProps: {
      sx: { height: '70vh' }
    },
    onGlobalFilterChange: setGlobalFilter,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <CsButton
          icon={faPlus}
          label="New"
          onClick={() => {
            setFormValues({ status: 'Active' });
            setProfileImgPreview('');
            table.setCreatingRow(
              createRow(table, {
                created_at: new Date(),
                updated_at: new Date(),
                status: 'Active'
              })
            );
          }}
        />

        {!data.length || searchedWithGlobal ? (
          <CsButton
            label="Load Data"
            onClick={() => {
              loadData();
              setSearchedWithGlobal(false);
            }}
          />
        ) : null}

        {data.length && !searchedWithGlobal ? (
          <CsButton
            label="Load More"
            onClick={() => {
              loadMoreData();
            }}
          />
        ) : null}
      </Box>
    ),
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              if (row.original.profile_img) {
                getProfileImageDownloadUrl(row.original.profile_img).then(
                  url => {
                    setProfileImgPreview(url);
                  }
                );
              }
              setPreviousProfileImg(row.original.profile_img || '');
              setFormValues(row.original);
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Add Candidate</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {renderFormFields()}
          <TextField
            label="Status"
            variant="standard"
            select
            value={formValues.status || 'Active'}
            disabled
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Candidate</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {renderFormFields()}
          <TextField
            label="Status"
            variant="standard"
            select
            value={formValues.status || ''}
            onChange={e => {
              setFormValues({ ...formValues, status: e.target.value });
            }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate,
    onCreatingRowCancel: () => {
      setProfileImgPreview('');
    },
    onEditingRowCancel: () => {
      setProfileImgPreview('');
    }
  });

  return (
    <div className="candidates">
      <div className="candidates__list">
        <div className="table">
          <MaterialReactTable table={table} />
        </div>
      </div>
    </div>
  );
};

export default Candidates;
