import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Elections from './Elections';
import Positions from './Positions';
import Candidates from './Candidates';
import Polls from './Polls';
import ElectionResults from './ElectionResults';
import { electionManagementAuth } from '../../core/services/elections';

const ElectionManagement = () => {
  return (
    <Routes>
      <Route path="/elections" element={<Elections />} />
      <Route path="/positions" element={<Positions />} />
      <Route path="/candidates" element={<Candidates />} />
      <Route path="/polls" element={<Polls />} />
      <Route path="/election-results" element={<ElectionResults />} />
      {/* <Route path="/client-vote-logs" element={<ClientVoteLogs />} /> */}
    </Routes>
  );
};

export default ElectionManagement;
