import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
  query,
  where,
  limit,
  startAfter,
  orderBy,
  getDoc
} from 'firebase/firestore';
import { db } from '../../firebase';

const electionsCollection = collection(db, 'v_elections');
const authElection = collection(db, '_aa_dummy');

export const getElections = async ({ action, batchLimit, start, search }) => {
  try {
    let q;
    if (action === 'load') {
      q = query(electionsCollection, orderBy('created_at'), limit(batchLimit));
    } else if (action === 'more') {
      q = query(
        electionsCollection,
        orderBy('created_at'),
        startAfter(start),
        limit(batchLimit)
      );
    } else if (action === 'search') {
      q = query(
        electionsCollection,
        where('title', '>=', search),
        where('title', '<=', search + '\uf8ff')
      );
    }

    const querySnapshot = await getDocs(q);
    const elections = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    const last = querySnapshot.docs[querySnapshot.docs.length - 1];

    return { elections, last };
  } catch (error) {
    return error;
  }
};

export const createElection = async data => {
  try {
    const res = await addDoc(electionsCollection, {
      title: data.title,
      start_date: data.start_date,
      end_date: data.end_date,
      created_at: new Date().getTime(),
      updated_at: new Date().getTime(),
      status: data.status
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const updateElection = async data => {
  try {
    const res = await updateDoc(doc(db, 'v_elections', data.id), {
      title: data.title,
      start_date: data.start_date,
      end_date: data.end_date,
      updated_at: new Date().getTime(),
      status: data.status
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const deleteElection = async id => {
  try {
    const res = await deleteDoc(doc(db, 'v_elections', id));

    return res;
  } catch (error) {
    return error;
  }
};

export const electionManagementAuth = async () => {
  try {
    const docSnap = doc(authElection, 'electionauth');
    const code = (Math.floor(Math.random() * 9999) + 1000).toString();

    await updateDoc(docSnap, { code });

    return code;
  } catch (error) {
    return error;
  }
};
