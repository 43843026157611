import './../../styles/pages/member-voting.scss';
import React, { useEffect, useState } from 'react';
import {
  getElections,
  getElectionResults,
  getClientVoteLogs
} from '../../core/services/voting';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { getUserFromSessionStorage } from '../../core/services/auth';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { electionManagementAuth } from '../../core/services/elections';

const csvConfig = mkConfig({
  useKeysAsHeaders: true,
  showColumnHeaders: false
});

const ElectionResults = () => {
  const [authed, setAuthed] = useState(false);

  useEffect(() => {
    (async () => {
      const code = await electionManagementAuth();

      if (window !== undefined) {
        if (window.prompt('Are you sure you want to proceed?') === code) {
          setAuthed(true);
        } else {
          setAuthed(false);
        }
      }
    })();
  }, []);

  const user = getUserFromSessionStorage();
  const [elections, setElections] = useState([]);
  const [selectedElection, setSelectedElection] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [clientId, setClientId] = useState('');
  const [clientVoteLogs, setClientVoteLogs] = useState(null);
  const [selectedVoteLogElection, setSelectedVoteLogElection] = useState('');

  useEffect(() => {
    if (authed) {
      const fetchElections = async () => {
        const res = await getElections();
        setElections(res.elections);
      };
      fetchElections();
    }
  }, [authed]);

  const handleElectionChange = event => {
    setSelectedElection(event.target.value);
  };

  const handleVoteLogElectionChange = event => {
    setSelectedVoteLogElection(event.target.value);
  };

  const handleGenerateResults = async () => {
    const data = [];

    setLoading(true);
    const res = await getElectionResults(selectedElection);
    setResults(res.results);
    setLoading(false);

    if (res.results) {
      const d = res.results;

      data.push({
        Candidate: 'TOTAL VOTES',
        Votes: d.totalVotes
      });

      data.push({
        Candidate: 'MEMBERS VOTED',
        Votes: d.membersVoted
      });

      d.positions.forEach(position => {
        position.candidates.forEach(candidate => {
          data.push({
            Candidate: position.name + ' - ' + candidate.name,
            Votes: candidate.votes
          });
        });
      });

      data.push({
        Candidate: '',
        Votes: ''
      });

      data.push({
        Candidate: d.poll.question,
        Votes: ''
      });

      d.poll.answers.forEach(answer => {
        data.push({
          Candidate: answer.answer,
          Votes: answer.count
        });
      });

      data.push({
        Candidate: 'TOTAL',
        Votes: d.poll.total
      });
    }

    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const handleClientIdChange = event => {
    setClientId(event.target.value);
  };

  const handleFetchClientVoteLogs = async () => {
    setLoading(true);
    const res = await getClientVoteLogs(clientId, selectedVoteLogElection);
    setClientVoteLogs(res.voteLogs);
    setLoading(false);
  };

  if (user.role !== 'admin') {
    return <Typography variant="h6">Access Denied</Typography>;
  }

  const groupedVoteLogs = clientVoteLogs?.reduce((acc, log) => {
    if (!acc[log.position]) {
      acc[log.position] = [];
    }
    acc[log.position].push(log.candidate);
    return acc;
  }, {});

  return authed ? (
    <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Election Results
      </Typography>
      <FormControl variant="standard" fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="select-election-label">Select Election</InputLabel>
        <Select
          labelId="select-election-label"
          value={selectedElection}
          onChange={handleElectionChange}
        >
          {elections.map(election => (
            <MenuItem key={election.id} value={election.id}>
              {election.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerateResults}
        disabled={!selectedElection || loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Generate Results'}
      </Button>
      {results && (
        <Box sx={{ marginTop: 4 }}>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: 'none',
              borderRadius: 0,
              border: '1px solid #ccc'
            }}
          >
            <Table sx={{ borderCollapse: 'collapse' }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ border: '1px solid #ccc' }}>
                    TOTAL VOTES
                  </TableCell>
                  <TableCell
                    sx={{ border: '1px solid #ccc', color: 'red' }}
                    align="right"
                  >
                    <b>{results.totalVotes}</b>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ border: '1px solid #ccc' }}>
                    MEMBERS VOTED
                  </TableCell>
                  <TableCell
                    sx={{ border: '1px solid #ccc', color: 'red' }}
                    align="right"
                  >
                    <b>{results.membersVoted}</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.positions.map(position => (
                  <React.Fragment key={position.id}>
                    <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
                      <TableCell
                        sx={{ border: '1px solid #ccc', fontWeight: 'bold' }}
                        colSpan={2}
                      >
                        {position.name}
                      </TableCell>
                    </TableRow>
                    {position.candidates.map(candidate => (
                      <TableRow key={candidate.id}>
                        <TableCell
                          sx={{ border: '1px solid #ccc' }}
                          component="th"
                          scope="row"
                        >
                          {candidate.name}
                        </TableCell>
                        <TableCell
                          sx={{ border: '1px solid #ccc' }}
                          align="right"
                        >
                          {candidate.votes}
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
                <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
                  <TableCell
                    sx={{ border: '1px solid #ccc', fontWeight: 'bold' }}
                    colSpan={2}
                  >
                    {results?.poll?.question}
                  </TableCell>
                </TableRow>
                {results?.poll?.answers?.map(answer => (
                  <TableRow key={answer.answer}>
                    <TableCell
                      sx={{ border: '1px solid #ccc' }}
                      component="th"
                      scope="row"
                    >
                      {answer.answer}
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #ccc' }} align="right">
                      {answer.count}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    sx={{ border: '1px solid #ccc' }}
                    component="th"
                    scope="row"
                  >
                    Total
                  </TableCell>
                  <TableCell sx={{ border: '1px solid #ccc' }} align="right">
                    {results.poll.total}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  ) : null;
};

export default ElectionResults;
