import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
  query,
  where,
  limit,
  startAfter,
  orderBy
} from 'firebase/firestore';
import { db } from '../../firebase';

const pollsCollection = collection(db, 'v_polls');

export const getPolls = async ({ action, batchLimit, start, search }) => {
  try {
    let q;
    if (action === 'load') {
      q = query(pollsCollection, orderBy('created_at'), limit(batchLimit));
    } else if (action === 'more') {
      q = query(
        pollsCollection,
        orderBy('created_at'),
        startAfter(start),
        limit(batchLimit)
      );
    } else if (action === 'search') {
      q = query(
        pollsCollection,
        where('question', '>=', search),
        where('question', '<=', search + '\uf8ff')
      );
    }

    const querySnapshot = await getDocs(q);
    const polls = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    const last = querySnapshot.docs[querySnapshot.docs.length - 1];

    return { polls, last };
  } catch (error) {
    return error;
  }
};

export const createPoll = async data => {
  try {
    const res = await addDoc(pollsCollection, {
      election_id: data.election_id,
      question: data.question,
      options: data.options,
      created_at: new Date().getTime(),
      updated_at: new Date().getTime(),
      status: data.status
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const updatePoll = async data => {
  try {
    const res = await updateDoc(doc(db, 'v_polls', data.id), {
      election_id: data.election_id,
      question: data.question,
      options: data.options,
      updated_at: new Date().getTime(),
      status: data.status
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const deletePoll = async id => {
  try {
    const res = await deleteDoc(doc(db, 'v_polls', id));

    return res;
  } catch (error) {
    return error;
  }
};
