import './../../styles/pages/positions.scss';
import React, { useEffect, useMemo, useState } from 'react';
import {
  getPositions,
  createPosition,
  updatePosition,
  deletePosition
} from '../../core/services/positions';
import { getElections } from '../../core/services/elections';
import { dateFormat } from '../../core/utils/dateFormat';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable
} from 'material-react-table';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import CsButton from './../../comps/CsButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Positions = () => {
  const [data, setData] = useState([]);
  const [elections, setElections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [lastDocument, setLastDocument] = useState();
  const [globalFilter, setGlobalFilter] = useState();
  const [searchedWithGlobal, setSearchedWithGlobal] = useState(false);
  const [clientTypesValues, setClientTypesValues] = useState([]);
  const [formValues, setFormValues] = useState({});

  const loadData = () => {
    setIsLoading(true);

    getPositions({ action: 'load', batchLimit: limit }).then(res => {
      setData(res.positions);
      setLastDocument(res.last);
      setIsLoading(false);
    });
  };

  const loadMoreData = () => {
    setIsLoading(true);

    getPositions({
      action: 'more',
      batchLimit: limit,
      start: lastDocument
    }).then(res => {
      setData([...data, ...res.positions]);
      setLastDocument(res.last);
      setIsLoading(false);
    });
  };

  const loadSearchData = () => {
    setIsLoading(true);

    getPositions({
      action: 'search',
      batchLimit: null,
      start: null,
      search: globalFilter
    }).then(res => {
      setData(res.positions);
      setIsLoading(false);
    });
  };

  const loadElections = async () => {
    const res = await getElections({ action: 'load', batchLimit: 100 });
    setElections(res.elections);
  };

  useEffect(() => {
    loadElections();
    loadData();
  }, []);

  useEffect(() => {
    if (globalFilter !== undefined) {
      loadSearchData();
      setSearchedWithGlobal(true);
    }
  }, [globalFilter]);

  const handleUpdate = async ({ row, values, table }) => {
    table.setEditingRow(null);
    setIsLoading(true);
    await updatePosition({
      ...formValues,
      client_types: clientTypesValues,
      id: row.original.id
    });
    loadData();
  };

  const handleCreate = async ({ row, values, table }) => {
    table.setCreatingRow(null);
    setIsLoading(true);
    await createPosition({
      ...formValues,
      client_types: clientTypesValues
    });
    loadData();
  };

  const openDeleteConfirmModal = async row => {
    if (
      window.confirm(`Are you sure you want to delete ${row.original.title}?`)
    ) {
      setIsLoading(true);
      await deletePosition(row.original.id);
      loadData();
    }
  };

  const clientTypes = ['Member', 'Associate', 'Retiree', 'Staff'];

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
        size: 50
      },
      {
        accessorKey: 'election_id',
        header: 'Election',
        size: 50,
        Cell: ({ cell }) => {
          const election = elections.find(e => e.id === cell.getValue());
          return election ? election.title : 'Unknown';
        }
      },
      {
        accessorKey: 'vote_count',
        header: 'Vote Count',
        size: 50
      },
      {
        accessorKey: 'client_types',
        header: 'Client Types',
        size: 50,
        Cell: ({ cell }) => (cell.getValue() || []).join(', ')
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 50
      }
    ],
    [elections]
  );

  const renderFormFields = () => (
    <>
      <TextField
        label="Title"
        variant="standard"
        value={formValues.title || ''}
        onChange={e => {
          setFormValues({ ...formValues, title: e.target.value });
        }}
      />
      <TextField
        label="Election"
        variant="standard"
        select
        value={formValues.election_id || ''}
        onChange={e => {
          setFormValues({ ...formValues, election_id: e.target.value });
        }}
      >
        {elections.map(election => (
          <MenuItem key={election.id} value={election.id}>
            {election.title}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Vote Count"
        variant="standard"
        type="number"
        value={formValues.vote_count || ''}
        onChange={e => {
          setFormValues({ ...formValues, vote_count: e.target.value });
        }}
      />
      <Box>
        <Typography sx={{ fontSize: 12 }}>Client Types</Typography>
        {clientTypes.map(type => (
          <FormControlLabel
            key={type}
            control={
              <Checkbox
                checked={clientTypesValues.includes(type)}
                onChange={event => {
                  const newValue = event.target.checked
                    ? [...clientTypesValues, type]
                    : clientTypesValues.filter(v => v !== type);
                  setClientTypesValues(newValue);
                }}
              />
            }
            label={type}
          />
        ))}
      </Box>
    </>
  );

  const table = useMaterialReactTable({
    columns,
    data,
    createDisplayMode: 'modal',
    enableRowActions: true,
    enableEditing: true,
    manualFiltering: true,
    manualPagination: true,
    enableBottomToolbar: true,
    state: {
      isLoading,
      globalFilter
    },
    muiTableContainerProps: {
      sx: { height: '70vh' }
    },
    onGlobalFilterChange: setGlobalFilter,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <CsButton
          icon={faPlus}
          label="New"
          onClick={() => {
            setClientTypesValues([]);
            setFormValues({ status: 'Active' });
            table.setCreatingRow(
              createRow(table, {
                created_at: new Date(),
                updated_at: new Date(),
                status: 'Active'
              })
            );
          }}
        />

        {!data.length || searchedWithGlobal ? (
          <CsButton
            label="Load Data"
            onClick={() => {
              loadData();
              setSearchedWithGlobal(false);
            }}
          />
        ) : null}

        {data.length && !searchedWithGlobal ? (
          <CsButton
            label="Load More"
            onClick={() => {
              loadMoreData();
            }}
          />
        ) : null}
      </Box>
    ),
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setClientTypesValues(row.original.client_types || []);
              setFormValues(row.original);
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Add Position</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {renderFormFields()}
          <TextField
            label="Status"
            variant="standard"
            select
            value={formValues.status || 'Active'}
            disabled
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Position</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {renderFormFields()}
          <TextField
            label="Status"
            variant="standard"
            select
            value={formValues.status || ''}
            onChange={e => {
              setFormValues({ ...formValues, status: e.target.value });
            }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onEditingRowSave: handleUpdate,
    onCreatingRowSave: handleCreate
  });

  return (
    <div className="positions">
      <div className="positions__list">
        <div className="table">
          <MaterialReactTable table={table} />
        </div>
      </div>
    </div>
  );
};

export default Positions;
