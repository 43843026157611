import {
  faBookOpenReader,
  faCertificate,
  faChartSimple,
  faCog,
  faFileAlt,
  faHomeAlt,
  faUsers,
  faVoteYea
} from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import CsLink from '../CsLink';
import { Link } from 'react-router-dom';
import {
  getUserFromSessionStorage,
  logUser,
  logout
} from '../../core/services/auth';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const AdminSidebar = () => {
  const user = getUserFromSessionStorage();

  return (
    <>
      <CsLink href={'/dashboard'} icon={faHomeAlt} name={'Dashboard'} />
      <CsLink
        href={'/manage-soa'}
        icon={faFileAlt}
        name={'Manage SOA'}
        subLinks={[
          {
            name: 'Upload SOA',
            href: '/upload'
          },
          {
            name: 'Delete SOA',
            href: '/delete'
          }
        ]}
      />
      <CsLink
        href={'/manage-members'}
        icon={faUsers}
        name={'Manage Members'}
        subLinks={[
          {
            name: 'Members',
            href: '/members'
          },
          {
            name: 'Logs',
            href: '/logs'
          }
        ]}
      />
      <CsLink
        href={'/manage-products'}
        icon={faChartSimple}
        name={'Manage Products'}
        subLinks={[
          {
            name: 'Loans',
            href: '/loans'
          },
          {
            name: 'Calculators',
            href: '/calculators'
          }
        ]}
      />
      <CsLink
        href={'/resources'}
        icon={faBookOpenReader}
        name={'Learning Resources'}
      />
      <CsLink
        href={'/pre-membership'}
        icon={faCertificate}
        name={'Pre-membership'}
      />

      <CsLink
        href={'/election-management'}
        icon={faVoteYea}
        name={'Election Management'}
        subLinks={[
          {
            name: 'Elections',
            href: '/elections'
          },
          {
            name: 'Positions',
            href: '/positions'
          },
          {
            name: 'Candidates',
            href: '/candidates'
          },
          {
            name: 'Polls',
            href: '/polls'
          },
          {
            name: 'Election Results',
            href: '/election-results'
          }
          // {
          //   name: 'Client Vote Logs',
          //   href: '/client-vote-logs'
          // }
        ]}
      />

      <CsLink href={'/settings'} icon={faCog} name={'Admin Settings'} />
    </>
  );
};

const MemberSidebar = () => {
  const user = getUserFromSessionStorage();

  return (
    <>
      <CsLink href={'/dashboard'} icon={faHomeAlt} name={'Dashboard'} />
      <CsLink
        href={'/products'}
        icon={faFileAlt}
        name={'Cooperative Products'}
        subLinks={[
          {
            href: '/loan-application',
            name: 'Loan Application'
          },
          {
            href: '/loan-details-calc',
            name: 'Loan Details and Calculator'
          }
        ]}
      />
      <CsLink href={'/announcements'} icon={faUsers} name={'Announcements'} />
      <CsLink
        href={'/resources'}
        icon={faBookOpenReader}
        name={'Learning Resources'}
      />
      <CsLink
        href={'/soa'}
        icon={faChartSimple}
        name={'Statement of Account'}
        subLinks={[
          {
            href: '/member-loans',
            name: 'Loans'
          },
          {
            href: '/member-savings',
            name: 'Savings'
          }
        ]}
      />

      {/* <CsLink href={'/election'} icon={faBookOpenReader} name={'Election'} /> */}

      <CsLink href={'/settings'} icon={faCog} name={'Settings'} />
    </>
  );
};

const Sidebar = ({ showSidebar, setShowSidebar }) => {
  const user = getUserFromSessionStorage();
  const [isAdmin] = useState(user?.role === 'admin');

  const handleLogout = async () => {
    await logUser({ log_type: 'logout', user });
    logout();
  };

  return (
    <div className={`sidebar ${showSidebar ? 'show' : ''}`}>
      <div className="close-sidebar">
        <IconButton
          onClick={() => {
            setShowSidebar(false);
          }}
        >
          <Close />
        </IconButton>
      </div>
      <div className="sidebar__section">
        <div className="sidebar__logo">
          <img src="/logo.png" alt="logo" className="logo" />
        </div>

        <div className="sidebar__user-info">
          <div className="user-info__avatar"></div>

          <div className="user-info__name">
            <p className="name">
              {[user?.first_name, user?.last_name]?.join(' ')?.toUpperCase()}
            </p>
            <p className="role">{user?.role?.toUpperCase()}</p>
          </div>
        </div>

        <div className="sidebar__navigation">
          {isAdmin ? <AdminSidebar /> : <MemberSidebar />}
        </div>
      </div>

      <div className="sidebar__logout">
        <Link href={'/login'} onClick={handleLogout}>
          Logout
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
