import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
  query,
  where,
  limit,
  startAfter,
  orderBy
} from 'firebase/firestore';
import { getUserFromSessionStorage } from './../services/auth';
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject
} from 'firebase/storage';
import { db, storage } from '../../firebase';

const candidatesCollection = collection(db, 'v_candidates');

export const getCandidates = async ({ action, batchLimit, start, search }) => {
  try {
    let q;
    if (action === 'load') {
      q = query(candidatesCollection, orderBy('created_at'), limit(batchLimit));
    } else if (action === 'more') {
      q = query(
        candidatesCollection,
        orderBy('created_at'),
        startAfter(start),
        limit(batchLimit)
      );
    } else if (action === 'search') {
      q = query(
        candidatesCollection,
        where('full_name', '>=', search),
        where('full_name', '<=', search + '\uf8ff')
      );
    }

    const querySnapshot = await getDocs(q);
    const candidates = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    const last = querySnapshot.docs[querySnapshot.docs.length - 1];

    return { candidates, last };
  } catch (error) {
    return error;
  }
};

export const createCandidate = async data => {
  try {
    const user = getUserFromSessionStorage();
    let profile_img = null;

    if (data.file) {
      profile_img =
        user.id +
        '_' +
        new Date().getTime() +
        '.' +
        data.file.name.split('.').pop();
      await uploadBytes(
        ref(storage, 'elections/profile_images/' + profile_img),
        data.file
      );
    }

    const res = await addDoc(candidatesCollection, {
      position_id: data.position_id,
      client_id: data.client_id,
      full_name: data.full_name,
      profile_img,
      info: data.info,
      created_at: new Date().getTime(),
      updated_at: new Date().getTime(),
      status: data.status
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const updateCandidate = async data => {
  try {
    const user = getUserFromSessionStorage();
    let profile_img = null;

    if (data.file) {
      profile_img =
        user.id +
        '_' +
        new Date().getTime() +
        '.' +
        data.file.name.split('.').pop();
      await uploadBytes(
        ref(storage, 'elections/profile_images/' + profile_img),
        data.file
      );
      await deleteObject(
        ref(storage, 'elections/profile_images/' + data.profile_img)
      );
    }

    const details = {
      position_id: data.position_id,
      client_id: data.client_id,
      full_name: data.full_name,
      info: data.info,
      updated_at: new Date().getTime(),
      status: data.status
    };

    const res = await updateDoc(
      doc(db, 'v_candidates', data.id),
      data.file
        ? {
            profile_img,
            ...details
          }
        : details
    );

    return res;
  } catch (error) {
    debugger;
    return error;
  }
};

export const deleteCandidate = async id => {
  try {
    const res = await deleteDoc(doc(db, 'v_candidates', id));

    return res;
  } catch (error) {
    return error;
  }
};

export const getProfileImageDownloadUrl = async filename => {
  try {
    const url = await getDownloadURL(
      ref(storage, 'elections/profile_images/' + filename)
    );
    return url;
  } catch (error) {
    return error;
  }
};
