import {
  collection,
  getDocs,
  query,
  where,
  doc,
  setDoc,
  getCountFromServer,
  orderBy,
  limit
} from 'firebase/firestore';
import { db } from '../../firebase';

const electionsCollection = collection(db, 'v_elections');
const pollsCollection = collection(db, 'v_polls');
const positionsCollection = collection(db, 'v_positions');
const candidatesCollection = collection(db, 'v_candidates');
const votesCollection = collection(db, 'v_votes');

const groupBy = (array, property) => {
  return array.reduce((acc, obj) => {
    const key = obj[property]; // Get the value of the selected property
    if (!acc[key]) {
      acc[key] = []; // Initialize an empty array for the key if it doesn't exist
    }
    acc[key].push(obj); // Add the current object to the array for the selected key
    return acc;
  }, {});
};

const removeDuplicates = (array, properties) => {
  const seen = new Set();
  return array.filter(obj => {
    // Create a unique key by combining the values of the specified properties
    const key = properties.map(prop => obj[prop]).join('_'); // Join property values with an underscore
    if (seen.has(key)) {
      return false; // If the key already exists, exclude the object
    }
    seen.add(key); // Add the key to the Set
    return true; // Keep the object if it's unique
  });
};

export const getActiveElection = async () => {
  try {
    const q = query(
      electionsCollection,
      where('status', '==', 'Active'),
      orderBy('created_at', 'desc'),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    const election = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }))[0];
    return { election };
  } catch (error) {
    console.error('Error fetching active election:', error);
    return { election: null };
  }
};

export const getElections = async () => {
  try {
    const q = query(electionsCollection, orderBy('created_at', 'desc'));
    const querySnapshot = await getDocs(q);
    const elections = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return { elections };
  } catch (error) {
    console.error('Error fetching elections:', error);
    return { elections: [] };
  }
};

export const getPolls = async electionId => {
  try {
    const q = query(
      pollsCollection,
      where('election_id', '==', electionId),
      where('status', '==', 'Active')
    );
    const querySnapshot = await getDocs(q);
    const polls = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return { polls };
  } catch (error) {
    console.error('Error fetching polls:', error);
    return { polls: [] };
  }
};

export const getElectionResults = async electionId => {
  try {
    const positionsQuery = query(
      positionsCollection,
      where('election_id', '==', electionId)
    );
    const positionsSnapshot = await getDocs(positionsQuery);
    const positions = positionsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    const results = {
      totalVotes: 0,
      membersVoted: 0,
      positions: [],
      poll: {
        total: 0,
        question: '',
        answers: [
          // {
          //   answer: '',
          //   count: 0
          // }
        ]
      }
    };

    const uniqueVoters = new Set();

    for (const position of positions) {
      const candidatesQuery = query(
        candidatesCollection,
        where('position_id', '==', position.id)
      );
      const candidatesSnapshot = await getDocs(candidatesQuery);
      const candidates = candidatesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const positionResults = {
        id: position.id,
        name: position.title,
        candidates: []
      };

      for (const candidate of candidates) {
        const votesQuery = query(
          votesCollection,
          where('election_id', '==', electionId),
          where('candidate_id', '==', candidate.id)
        );

        const votes = await getDocs(votesQuery);
        const votesSnapshot = removeDuplicates(
          votes.docs.map(doc => doc.data()),
          ['caster_name', 'candidate_name']
        );

        const votesCount = votesSnapshot.length;
        results.totalVotes += votesCount;

        votesSnapshot.forEach(doc => {
          uniqueVoters.add(doc.caster_name);
        });

        positionResults.candidates.push({
          id: candidate.id,
          name: candidate.full_name,
          votes: votesCount
        });
      }

      results.positions.push(positionResults);
    }

    results.membersVoted = uniqueVoters.size;

    const pollData = [];
    const uniquePollAnswers = new Set();

    const pollQuery = query(
      votesCollection,
      where('election_id', '==', electionId),
      where('candidate_id', '==', null)
    );

    const polls = await getDocs(pollQuery);
    const pollSnapshot = removeDuplicates(
      polls.docs.map(doc => doc.data()),
      ['caster_name']
    );
    pollSnapshot.forEach(doc => {
      const { question, answer } = doc.poll;

      uniquePollAnswers.add(answer);
      pollData.push({ question, answer });

      results.poll.question = question;
    });

    uniquePollAnswers.forEach(answer => {
      const count = pollData.filter(poll => poll.answer === answer).length;
      results.poll.answers.push({ answer, count });
      results.poll.total += count;
    });

    return { results };
  } catch (error) {
    console.error('Error fetching election results:', error);
    return { results: null };
  }
};

export const getPositions = async (clientType, electionId) => {
  try {
    const q = query(
      positionsCollection,
      where('election_id', '==', electionId)
    );
    const querySnapshot = await getDocs(q);
    const positions = querySnapshot.docs
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      .filter(position => position.client_types.includes(clientType));
    return { positions };
  } catch (error) {
    console.error('Error fetching positions:', error);
    return { positions: [] };
  }
};

export const getCandidatesByPosition = async positionId => {
  try {
    const q = query(
      candidatesCollection,
      where('position_id', '==', positionId)
    );
    const querySnapshot = await getDocs(q);
    const candidates = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return { candidates };
  } catch (error) {
    console.error('Error fetching candidates:', error);
    return { candidates: [] };
  }
};

export const hasUserVoted = async (electionId, casterName) => {
  try {
    const q = query(
      votesCollection,
      where('election_id', '==', electionId),
      where('caster_name', '==', casterName)
    );
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count > 0;
  } catch (error) {
    console.error('Error checking if user has voted:', error);
    return false;
  }
};

export const castVote = async (
  electionId,
  candidateId,
  casterName,
  candidatePosition,
  candidateName,
  clientId,
  clientType,
  poll
) => {
  try {
    const voteRef = doc(votesCollection);
    await setDoc(voteRef, {
      election_id: electionId,
      candidate_id: candidateId,
      candidate_position: candidatePosition,
      candidate_name: candidateName,
      caster_name: casterName,
      client_id: clientId,
      client_type: clientType,
      casted_at: new Date().getTime(),
      created_at: new Date().getTime(),

      poll
    });
    return voteRef;
  } catch (error) {
    console.error('Error casting vote:', error);
    return error;
  }
};

export const getClientVoteLogs = async (clientId, electionId) => {
  try {
    const q = query(votesCollection, where('election_id', '==', electionId));
    const logs = await getDocs(q);
    const logSnapshot = removeDuplicates(
      logs.docs.map(doc => doc.data()),
      ['caster_name', 'candidate_name']
    );
    // const logSnapshot = logs.docs.map(doc => doc.data());
    const voteLogs = logSnapshot.map(doc => ({
      client_id: doc.client_id,
      caster_name: doc.caster_name,
      vote: doc?.candidate_name
        ? `${doc.candidate_position} - ${doc.candidate_name}`
        : doc?.poll?.question,
      answer: doc?.poll?.answer
    }));
    return { voteLogs };
  } catch (error) {
    console.error('Error fetching client vote logs:', error);
    return { voteLogs: [] };
  }
};
