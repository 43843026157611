import './../../styles/pages/polls.scss';
import React, { useEffect, useMemo, useState } from 'react';
import {
  getPolls,
  createPoll,
  updatePoll,
  deletePoll
} from '../../core/services/polls';
import { getElections } from '../../core/services/elections';
import { dateFormat } from '../../core/utils/dateFormat';
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable
} from 'material-react-table';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  MenuItem,
  TextField
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import CsButton from './../../comps/CsButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Polls = () => {
  const [data, setData] = useState([]);
  const [elections, setElections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [lastDocument, setLastDocument] = useState();
  const [globalFilter, setGlobalFilter] = useState();
  const [searchedWithGlobal, setSearchedWithGlobal] = useState(false);

  const loadData = () => {
    setIsLoading(true);

    getPolls({ action: 'load', batchLimit: limit }).then(res => {
      setData(res.polls);
      setLastDocument(res.last);
      setIsLoading(false);
    });
  };

  const loadMoreData = () => {
    setIsLoading(true);

    getPolls({
      action: 'more',
      batchLimit: limit,
      start: lastDocument
    }).then(res => {
      setData([...data, ...res.polls]);
      setLastDocument(res.last);
      setIsLoading(false);
    });
  };

  const loadSearchData = () => {
    setIsLoading(true);

    getPolls({
      action: 'search',
      batchLimit: null,
      start: null,
      search: globalFilter
    }).then(res => {
      setData(res.polls);
      setIsLoading(false);
    });
  };

  const loadElections = async () => {
    const res = await getElections({ action: 'load', batchLimit: 100 });
    setElections(res.elections);
  };

  useEffect(() => {
    loadElections();
    loadData();
  }, []);

  useEffect(() => {
    if (globalFilter !== undefined) {
      loadSearchData();
      setSearchedWithGlobal(true);
    }
  }, [globalFilter]);

  const handleUpdate = async ({ row, values, table }) => {
    table.setEditingRow(null);
    setIsLoading(true);
    await updatePoll({
      ...values,
      id: row.original.id
    });
    loadData();
  };

  const handleCreate = async ({ row, values, table }) => {
    table.setCreatingRow(null);
    setIsLoading(true);
    await createPoll({
      ...values
    });
    loadData();
  };

  const openDeleteConfirmModal = async row => {
    if (
      window.confirm(
        `Are you sure you want to delete "${row.original.question}"?`
      )
    ) {
      setIsLoading(true);
      await deletePoll(row.original.id);
      loadData();
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'question',
        header: 'Question',
        size: 50
      },
      {
        accessorKey: 'options',
        header: 'Options',
        size: 50
      },
      {
        accessorKey: 'election_id',
        header: 'Election',
        size: 50,
        Cell: ({ cell }) => {
          const election = elections.find(e => e.id === cell.getValue());
          return election ? election.title : 'Unknown';
        },
        muiEditTextFieldProps: {
          select: true,
          children: elections.map(election => (
            <MenuItem key={election.id} value={election.id}>
              {election.title}
            </MenuItem>
          ))
        }
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 50,
        editVariant: 'select',
        editSelectOptions: [
          {
            label: 'Active',
            value: 'Active'
          },
          {
            label: 'Inactive',
            value: 'Inactive'
          }
        ]
      }
    ],
    [elections]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    createDisplayMode: 'modal',
    enableRowActions: true,
    enableEditing: true,
    manualFiltering: true,
    manualPagination: true,
    enableBottomToolbar: true,
    state: {
      isLoading,
      globalFilter
    },
    muiTableContainerProps: {
      sx: { height: '70vh' }
    },
    onGlobalFilterChange: setGlobalFilter,
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <CsButton
          icon={faPlus}
          label="New"
          onClick={() => {
            table.setCreatingRow(
              createRow(table, {
                created_at: new Date(),
                updated_at: new Date(),
                status: 'Active'
              })
            );
          }}
        />

        {!data.length || searchedWithGlobal ? (
          <CsButton
            label="Load Data"
            onClick={() => {
              loadData();
              setSearchedWithGlobal(false);
            }}
          />
        ) : null}

        {data.length && !searchedWithGlobal ? (
          <CsButton
            label="Load More"
            onClick={() => {
              loadMoreData();
            }}
          />
        ) : null}
      </Box>
    ),
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              table.setEditingRow(row);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              openDeleteConfirmModal(row);
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Add Poll</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Edit Poll</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleUpdate,
    onCreatingRowCancel: () => {},
    onEditingRowCancel: () => {}
  });

  return (
    <div className="polls">
      <div className="polls__list">
        <div className="table">
          <MaterialReactTable table={table} />
        </div>
      </div>
    </div>
  );
};

export default Polls;
